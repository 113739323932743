.counter-parent {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  align-items: stretch;
  user-select: none;
}

.counter-box {
  flex: 1 1 calc(25% - 1rem);
  min-width: 120px;
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 0.5em 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 0.3em solid #1ba0f220;
}

.counter-box:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}
  
  .counter-box:nth-child(1) {
    background-color: #fce4ec; /* Light Pink */
  }
  
  .counter-box:nth-child(2) {
    background-color: #e8f5e9; /* Light Green */
  }
  
  .counter-box:nth-child(3) {
    background-color: #fff3e0; /* Light Orange */
  }
  
  .counter-box:nth-child(4) {
    background-color: #ede7f6; /* Light Purple */
  }

  .counter > .top-row > .number {
    font-size: 3.5rem;
    color: #1ba0f2;
    margin-bottom: 0rem;
    margin-top: 0.2em;
  }

  .counter {
    text-align: center;
  }

  .underline-animation-header {
    font-size: 1.05rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .description {
    font-size: 0.8rem;
    color: #555;
  }

  @media (max-width: 1000px) {  
    .counter > .top-row > .number {
      font-size: 2.5rem;

    }
  }
    
  @media (max-width: 600px) {
    .counter-parent {
      gap: 0.5em;
    }

    .counter-box {
      flex: 1 1 100%;
      margin: 0.2em 0.5em;
    }

    .counter-box:hover {
      transform: scale(1);
    }

    .counter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  
    .counter .top-row {
      display: flex;
      align-items: center;
      width: 100%;
    }
  
    .counter > .top-row > .number {
      font-size: 2.5rem;
      margin-right: 1rem;
      margin-bottom: 0;
      margin-left: 0.2em;
    }
  
    .underline-animation-header {
      font-size: 1.2rem;
      flex: 1;
    }
  
    .description {
      font-size: 0.85rem;
      margin-top: 0.5rem;
      width: 100%;
    }
  }